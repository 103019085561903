

@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base{
    a{
        @apply text-gray-500  no-underline hover:text-green-700 dark:hover:text-green-700;
    }
    p{
        @apply dark:text-gray-200 text-base text-gray-800 ;
    }
    dd{
        @apply dark:text-gray-200;
    }
    dt{
        @apply dark:text-gray-100;
    }
    h3{
        @apply dark:text-gray-100;
    }
    h2{
        @apply dark:text-gray-100;
    }
    ul{
        @apply list-disc list-inside;
    }

}

@layer components{
    
    .btn-blue{
        @apply p-4 bg-green-600 rounded-lg font-bold text-white mt-5 hover:bg-gray-600 dark:text-gray-200
    },
    .a-nav{
        @apply flex items-center text-sm font-medium dark:text-gray-200 text-gray-700 hover:text-gray-800 dark:hover:text-green-700
    },
    .btn-custom{
        @apply w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white  md:py-4 md:text-lg md:px-10
    },
    .custom-p{
        @apply text-base dark:text-gray-100 text-gray-900 leading-8
    }
}

@layer utilities{
    .button-bg{
        background-color: #009688;
    }
    .text-color{
        color:  #009688;

    }
    .bg-customColor{
        background-color:  #009688;

    }
    .display_xl{
        height: 50vh;  
    }
    .display_md{
        height: 55vh;  
    }
    .display_sm{
        height: 100vh;  
    }
    .display{
        height: 80vh;  
    }
}
